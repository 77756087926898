@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Prata&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (max-width: 770px) {
  .rbc-btn-group {
    margin-top: 10px !important;
  }

  .rbc-toolbar {
    display: unset !important;
  }
}
.responsive-cirlce{
  width: 100%;
  aspect-ratio: 1/1;
}

iframe{
  aspect-ratio: 16/9;
  width: 100%;
}

.react-datetime-picker__wrapper {
  border: thin solid rgba(209, 213, 219, 0.5) !important;
  border-radius: 0.125rem !important;
  height: 100% !important;
}

.react-datetime-picker__inputGroup__input {
  height: auto !important;
}

.slider-gap{
  gap: 20px;
}

.slider-shadow{
  box-shadow: 10px 14px 24px 0px #34294E0F;
}

.carousel-button-group{
  top: -4.5rem;
}

.box-1{
  right: 42%;
  top: 0;
  padding: 1rem;
}

.box-2{
  right: 53%;
  top: 20%;
  padding: 1rem;

}
.box-3{
  right: 24%;
  top: 30%;
  padding: 1rem;

}

.box-4{
  right: 41%;
  top: 52%;
  padding: 1rem;

}

@media screen and (min-width: 1920px) and (max-width: 2300px) {
  .box-1{
    right: 44%;
    top: 0;
  }
  
  .box-2{
    right: 55%;
    top: 20%;
  
  }
  .box-3{
    right: 24%;
    top: 30%;
  
  }
  
  .box-4{
    right: 41%;
    top: 52%;
  
  }
}

@media screen and (min-width: 2300px) and (max-width: 2600px) {
  .box-1{
    right: 46%;
    top: 0;
  }
  
  .box-2{
    right: 57%;
    top: 20%;
  
  }
  .box-3{
    right: 24%;
    top: 30%;
  
  }
  
  .box-4{
    right: 46%;
    top: 52%;
  
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .box-1{
    right: 37.5%;
    top: 0;
  }
  
  .box-2{
    right: 48.5%;
    top: 20%;
  
  }
  .box-3{
    right: 18%;
    top: 30%;
  
  }
  
  .box-4{
    right: 36%;
    top: 52%;
  
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .box-1 {
    right: 33.5%;
    top: -3%;
}
  
.box-2 {
  right: 45.5%;
  top: 20%;
}
.box-3 {
  right: 12%;
  top: 30%;
}
  
.box-4 {
  right: 35%;
  top: 50%;
}
}
@media screen and (min-width: 950px) and (max-width: 1200px) {
  .box-1 {
    right: 29.5%;
    top: -3%;
}
  
.box-2 {
  right: 41.5%;
  top: 20%;
}
.box-3 {
  right: 6%;
  top: 30%;
}
  
.box-4 {
  right: 35%;
  top: 60%;
}
}
@media screen and (min-width: 768px) and (max-width: 950px) {
  .box-1 {
    right: 27.5%;
    top: -3%;
}
  
.box-2 {
  right: 38.5%;
  top: 30%;
}
.box-3 {
  right: -2%;
  top: 52%;
}
  
.box-4 {
  right: 35%;
  top: 75%;
}
}

@media screen and (min-width: 300px) and (max-width: 768px) {
  .responsive-cirlce{
    width: 50%;
    aspect-ratio: 1/1;
  }
  // .react-multi-carousel-item {
  //   width: 340px !important;
  // }
}
// @media screen and (min-width: 300px) {
//   .react-multi-carousel-item {
//     width: 430px !important;
//   }
// }